import { queryOptions, useQuery } from "@tanstack/react-query";
import { getUserInfoApi } from "@/network/user/userApi";
import type { CustomQueryOptions } from "@/types/reactQuery/reactQuery.type";
import type { UserResponse } from "@/types/user/user.type";

export type UserInfoQueryOptions = CustomQueryOptions<UserResponse>;

export const userInfoQueryOptions = (options?: UserInfoQueryOptions) =>
  queryOptions({
    queryKey: ["user", "info"],
    queryFn: () => getUserInfoApi(),
    ...options
  });

export const useUserInfoQuery = (options?: UserInfoQueryOptions) => {
  const {
    data,
    isFetching: userInfoLoading,
    error: userInfoError
  } = useQuery(userInfoQueryOptions(options));

  return {
    userInfo: data || ({} as UserResponse),
    userInfoLoading,
    userInfoError
  };
};
