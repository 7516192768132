import { queryOptions, useQuery } from "@tanstack/react-query";
import { getApplessBikeInfo } from "@/network/bike/bikeApi";
import type { BikeGetParams, BikeInfoResponse } from "@/types/bike/bike.type";
import type { CustomQueryOptions } from "@/types/reactQuery/reactQuery.type";

export type BikeInfoQueryOptions = CustomQueryOptions<BikeInfoResponse>;

export const bikeInfoQueryOptions = (
  params: BikeGetParams,
  options?: BikeInfoQueryOptions
) =>
  queryOptions({
    queryKey: ["bike", "info", params],
    queryFn: () => getApplessBikeInfo(params),
    ...options
  });

export const useBikeInfoQuery = (
  params: BikeGetParams,
  options?: BikeInfoQueryOptions
) => {
  const {
    data,
    isFetching: bikeInfoLoading,
    error: bikeInfoError
  } = useQuery(bikeInfoQueryOptions(params, options));

  return {
    bikeInfo: data || ({} as BikeInfoResponse),
    bikeInfoLoading,
    bikeInfoError
  };
};
