"use client";

import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { KaKaoSignUpButton } from "./KaKaoSignUpBtton";
import {
  bikeInfoQueryOptions,
  useBikeInfoQuery
} from "@/hooks/queries/useBikeInfoQuery";
import { useUserInfoQuery } from "@/hooks/queries/useUserInfoQuery";

const RidingLandingView = ({ sn }: { sn: string }) => {
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const router = useRouter();
  const queryClient = useQueryClient();

  const { userInfo } = useUserInfoQuery({
    enabled: !!access_token
  });

  const { bikeInfo } = useBikeInfoQuery(
    { sn },
    {
      enabled: !!sn && !access_token,
      throwOnError: true
    }
  );

  useEffect(() => {
    if (userInfo?.riding_hash) {
      router.replace(`/riding/controller?ridingId=${userInfo.riding_hash}`);
    }

    if (userInfo?.riding_hash === null) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(bikeInfoQueryOptions({ sn }));
    }
  }, [userInfo]);

  useEffect(() => {
    const hasSnQuery = location.href.includes("sn");
    cookies.set("sn", sn, { path: "/" });

    cookies.set(
      "scan_url",
      encodeURI(`${location.href}${hasSnQuery ? "" : `?sn=${sn}`}`),
      {
        path: "/"
      }
    );
  }, []);

  useEffect(() => {
    const hasErrorCode = bikeInfo && "code" in bikeInfo;
    if (hasErrorCode) {
      router.replace(`/riding/error?code=${bikeInfo.code}`);
      return;
    }

    const accessToken = cookies.get("access_token");
    if (accessToken && userInfo?.riding_hash === null) {
      router.replace("/riding/start");
    }
  }, [bikeInfo]);

  return (
    <>
      <KaKaoSignUpButton />
    </>
  );
};

export default RidingLandingView;
